import pkg, { ExperimentsConfig, setPackageState } from "./pkg";

export {
  getAssignmentBucket,
  getBucketMap,
  getBucket,
  BucketMap,
} from "./BucketingTasks";

export const HOLDOUT = "holdout" as const;

export const EXPERIMENT_ASSIGNMENTS_LOCAL_STORAGE_NAME =
  "experimentAssignments";
export const LOGGED_IN_USER_EXP_COOKIE_NAME = "loggedInUserExperimentUid";
export const LOGGED_OUT_USER_EXP_COOKIE_NAME = "loggedOutUserExperimentUid";

export {
  sendExperimentTriggerThroughKafkaRestProxy,
  EVENT_SOURCE,
} from "./experimentTriggers";
export function init(config: ExperimentsConfig) {
  if (pkg.initialized) {
    console.warn("@outschool/experiments-shared has already been initialized");
  } else {
    setPackageState(config);
  }
}

export enum RandomizationUnits {
  /** The activity_uid from the activities table */
  Activity = "Activity",
  /** The slug_id from the activities table */
  ActivitySlug = "ActivitySlug",
  /**
   * Applies to both logged in and logged out users
   * uses the experiment_uid stored in browser storage on the user's device
   */
  AllUsersAnonymousId = "AllUsersAnonymousId",
  /** The learner_uid from the learners table */
  Learner = "Learner",
  /**
   * The user_uid from the users table if the user is logged in,
   * else the experiment_uid stored in browser storage on the user's device
   */
  LoggedInOrLoggedOutUser = "LoggedInOrLoggedOutUser",
  /** The user_uid from the users table */
  LoggedInUser = "LoggedInUser",
  /** The experiment_uid stored in browser storage on the user's device */
  LoggedOutUser = "LoggedOutUser",
}

export type AssignmentRequest = Array<{
  randomizationUnit: RandomizationUnits;
  randomizationUnitIds: string[];
}>;

type AssignmentResponse = Array<{
  randomizationUnit: RandomizationUnits;
  randomizationUnitId: string;
  experimentName: string;
  variantAssigned: string;
}>;

type FetchFunction = (req: RequestInfo, opt: RequestInit) => Promise<Response>;
export type ExperimentAssignments = Record<string, string>;

let DEBUG_LOG_EXPERIMENTS_SERVICE_RESPONSE = false;

if (typeof process !== "undefined") {
  DEBUG_LOG_EXPERIMENTS_SERVICE_RESPONSE =
    process?.env?.DEBUG_LOG_EXPERIMENTS_SERVICE_RESPONSE === "enabled";
}

/**
 * Throws error in the event that the timeout exceeds given timeoutMs.
 * @param fetchPromise Promise for which we are timing against
 * @param timeoutMs Timeout time in ms
 * @returns Promise with Response.
 */
function fetchWithTimeout(
  fetchPromise: Promise<Response>,
  debugDescriptor: string,
  timeoutMs: number = 500
) {
  let timeoutRef: NodeJS.Timeout;

  return Promise.race<Response>([
    new Promise((_resolve, reject) => {
      timeoutRef = setTimeout(() => {
        reject(
          new Error(
            `Experiments service timed out in ${timeoutMs}ms: ${debugDescriptor}`
          )
        );

        if (DEBUG_LOG_EXPERIMENTS_SERVICE_RESPONSE) {
          fetchPromise
            .then(async response => {
              console.log(
                `timed out fetchPromise eventually resolved with ${response?.status}`,
                {
                  status: response?.status,
                  body: await response?.json?.(),
                  debugDescriptor,
                }
              );
            })
            .catch(err => {
              console.log("timed out fetchPromise eventually rejected with", {
                err,
                debugDescriptor,
              });
            });
        }
      }, timeoutMs);
    }),
    fetchPromise.finally(() => {
      clearTimeout(timeoutRef);
    }),
  ]);
}

export interface MakeGetRequestInterface {
  fetchFn: FetchFunction;
  randomizationUnit: RandomizationUnits;
  randomizationUnitId: string;
  experimentsServiceUrl: string;
  experimentsApiKey: string;
  debugDescriptor?: string;
  timeoutMs?: number;
}

export async function makeGetRequest({
  fetchFn,
  randomizationUnit,
  randomizationUnitId,
  experimentsServiceUrl,
  experimentsApiKey,
  debugDescriptor = "makeGetRequest",
  timeoutMs = undefined,
}: MakeGetRequestInterface): Promise<ExperimentAssignments> {
  // guard against undefined values that should not be there that will ultimately return a 400
  // loggedInUserExpUid needs to always be defined, loggedInUserExpUid can be undefined in the case
  // of a user that has never logged in

  if (!randomizationUnitId) {
    return {};
  }

  const resp = await fetchWithTimeout(
    fetchFn(
      `${experimentsServiceUrl}/assignment/${randomizationUnit}/${randomizationUnitId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${experimentsApiKey}`,
        },
      }
    ),
    debugDescriptor,
    timeoutMs
  );

  const assignmentResponse: AssignmentResponse = await resp.json();
  return assignmentResponse.reduce((obj: ExperimentAssignments, assignment) => {
    obj[assignment.experimentName] = assignment.variantAssigned;
    return obj;
  }, {} as ExperimentAssignments);
}

export async function fetchWebsiteExpAssignment({
  fetchFn,
  loggedInUserExpUid,
  loggedOutUserExpUid,
  experimentsServiceUrl,
  timeoutMs = undefined,
}: {
  fetchFn: FetchFunction;
  loggedInUserExpUid?: string;
  loggedOutUserExpUid?: string;
  experimentsServiceUrl: string;
  timeoutMs?: number;
}): Promise<ExperimentAssignments> {
  // guard against undefined values that should not be there that will ultimately return a 400
  // loggedInUserExpUid needs to always be defined, loggedInUserExpUid can be undefined in the case
  // of a user that has never logged in

  if (!loggedOutUserExpUid) {
    return {};
  }

  const resp = await fetchWithTimeout(
    fetchFn(
      `${experimentsServiceUrl}/assignment/website/${loggedOutUserExpUid}/${loggedInUserExpUid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ),
    "fetchWebsiteExpAssignment",
    timeoutMs
  );

  const assignmentResponse: AssignmentResponse = await resp.json();
  return assignmentResponse.reduce((obj: ExperimentAssignments, assignment) => {
    obj[assignment.experimentName] = assignment.variantAssigned;
    return obj;
  }, {} as ExperimentAssignments);
}

export function fetchLearnerExpAssignments({
  fetchFn,
  learnerUid,
  experimentsServiceUrl,
  experimentsApiKey,
  timeoutMs = undefined,
}: {
  fetchFn: FetchFunction;
  learnerUid: string;
  experimentsServiceUrl: string;
  experimentsApiKey: string;
  timeoutMs?: number;
}): Promise<ExperimentAssignments> {
  return makeGetRequest({
    fetchFn,
    randomizationUnit: RandomizationUnits.Learner,
    randomizationUnitId: learnerUid,
    experimentsServiceUrl,
    experimentsApiKey,
    debugDescriptor: "fetchLearnerExpAssignments",
    timeoutMs,
  });
}
import ldZipObject from "lodash/zipObject";

/**
 * The experiment state dictionary is encoded in the search params `experimentName` and `experimentAssigned`.
 * It looks like this:
 * outschool.com/?experimentName=experiment1,experiment2&variantAssigned=control,treatment
 * To decode the dictionary you have to [zipObject](https://lodash.com/docs/4.17.15#zipObject) the values of experiment name and variant assigned
 * `zip([experiment1, experiment2],[control,treatment])`
 * result: {experiment1: control, experiment2: treatment}
 *
 */
export function encodeExperimentAssignmentUrlQueryParams(
  url: URL,
  experimentAssignments: Record<string, string> = {}
): void {
  const experimentNames: string[] = Object.keys(experimentAssignments).sort();
  const variantsAssigned: string[] = [];
  for (const experimentName of experimentNames) {
    variantsAssigned.push(experimentAssignments[experimentName]);
  }

  if (experimentNames.length) {
    url.searchParams.set("experimentName", experimentNames.join(","));
  }
  if (variantsAssigned.length) {
    url.searchParams.set("variantAssigned", variantsAssigned.join(","));
  }
}

export function decodeExperimentAssignmentUrlQueryParams(
  url: URL
): Record<string, string> {
  let experimentAssignments: Record<string, string> = {};

  if (!!url) {
    const parseStrToArray = (input: string | null) => {
      if (!input) {
        return [];
      }
      return input.split(",");
    };

    // tempUrl is only used to grab the searchparams passed from ssr-cloudlflare-worker
    const tempUrl = new URL(url, "http://example.com");
    const experimentNames = parseStrToArray(
      tempUrl.searchParams.get("experimentName")
    );
    const variantsAssigned = parseStrToArray(
      tempUrl.searchParams.get("variantAssigned")
    );
    if (experimentNames.length !== variantsAssigned.length) {
      console.log(
        `Experiment name length != variants assigned | experimentNames: ${experimentNames} | variantsAssigned: ${variantsAssigned}`
      );
      experimentAssignments = {};
    }
    experimentAssignments = ldZipObject(experimentNames, variantsAssigned);
  }
  return experimentAssignments;
}
